import { addLocationChangeHandler } from './location';
import { addLoginHandler } from './login';
import { handleNavigation } from './navigation';
import { addInitialDataForWidgetPlugins } from './widget-plugins';
import { ContextProps, ContextServices } from '../../../types';

export const addHandlers = (
  contextProps: ContextProps,
  contextServices: ContextServices,
) => {
  addInitialDataForWidgetPlugins(contextProps, contextServices);
  addLocationChangeHandler(contextProps, contextServices);
  addLoginHandler(contextProps, contextServices);

  return handleNavigation(contextProps, contextServices);
};
