import { MembersAreaEvent } from '@wix/members-area-widget-plugin-lib/viewer';

import {
  ContextProps,
  ContextServices,
  Member,
  MembersIdentifiers,
  Nullable,
  State,
} from '../../../types';

const getMemberIdentifier = (member: Nullable<Member>) => {
  return member ? { id: member.id!, slug: member.profile?.slug! } : null;
};

const getMembersIdentifiersFromState = (state: State) => {
  const { currentMember, viewedMember } = state.members;
  const members: MembersIdentifiers = {
    currentMemberIdentifier: getMemberIdentifier(currentMember),
    viewedMemberIdentifier: getMemberIdentifier(viewedMember),
  };

  return members;
};

export const addInitialDataForWidgetPlugins = (
  { state }: Pick<ContextProps, 'state'>,
  { widgetPluginHostService }: Pick<ContextServices, 'widgetPluginHostService'>,
) => {
  const initialData = getMembersIdentifiersFromState(state);

  return widgetPluginHostService.setMembersData(initialData);
};

export const emitMemberChangedEventToPlugins = (
  { state }: Pick<ContextProps, 'state'>,
  { widgetPluginHostService }: Pick<ContextServices, 'widgetPluginHostService'>,
) => {
  const payload = getMembersIdentifiersFromState(state);
  return widgetPluginHostService.emitEvent({
    type: MembersAreaEvent.MemberChanged,
    payload,
  });
};

export const emitTabChangedEventToPlugins = (
  state: State,
  {
    widgetPluginHostService,
    routeDataService,
  }: Pick<ContextServices, 'widgetPluginHostService' | 'routeDataService'>,
) => {
  const { visibleWidgetId } = routeDataService.getRouteData();

  return widgetPluginHostService.emitEvent({
    type: MembersAreaEvent.TabChanged,
    payload: visibleWidgetId,
  });
};
