import { CurrentUserService, InstanceService } from '../../types';

export class CurrentUserFromInstanceService implements CurrentUserService {
  constructor(private readonly instanceService: InstanceService) {}

  getCurrentUserId() {
    const instance = this.instanceService.getInstance();
    return instance ? this.parseCurrentUserIdFromInstance(instance) : null;
  }

  private parseCurrentUserIdFromInstance(instance: string) {
    try {
      const [, base64Data] = instance.split('.', 2);
      const instanceData = JSON.parse(atob(base64Data)) as { uid?: string };
      return instanceData.uid ?? null;
    } catch {
      return null;
    }
  }
}
