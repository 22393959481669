import type {
  InitState,
  SettingsPageState,
  SettingsPageContextServices,
  SettingsPageContextProps,
  Nullable,
  FlowAPI,
} from '../../../types';

import { WARMUP_DATA_STATE_KEY } from '../../../constants';

type GetStateProps = {
  initState: InitState;
  flowAPI: FlowAPI;
  contextServices: Pick<
    SettingsPageContextServices,
    | 'monitoringService'
    | 'membersAreaPublicAPI'
    | 'pageService'
    | 'membersService'
    | 'warmupDataService'
  >;
};

type SerializableState = Pick<
  SettingsPageState,
  'currentMember' | 'routes' | 'settingsPagePrefix'
>;

type StateFetchers = Pick<
  SettingsPageState,
  'fetchCurrentMember' | 'fetchRouteConfigurations' | 'fetchSettingsPagePrefix'
>;

export const getSerializableState = ({
  routes,
  settingsPagePrefix,
  currentMember,
}: SettingsPageState) => {
  const serializableState: SerializableState = {
    routes,
    settingsPagePrefix,
    currentMember,
  };

  return JSON.stringify(serializableState);
};

const defaultSerializableState: SerializableState = {
  routes: [],
  currentMember: null,
  settingsPagePrefix: '',
};

export const getState = ({
  flowAPI,
  initState,
  contextServices,
}: GetStateProps) => {
  const { isSSR } = flowAPI.environment;
  const {
    pageService,
    membersAreaPublicAPI,
    membersService,
    warmupDataService,
    monitoringService,
  } = contextServices;

  let serializableState = defaultSerializableState;

  if (!isSSR) {
    try {
      const stateJson = warmupDataService.get<string>(WARMUP_DATA_STATE_KEY);
      const cachedState = stateJson ? JSON.parse(stateJson) : null;
      serializableState = cachedState ?? defaultSerializableState;
    } catch (e) {
      monitoringService.log(
        'Settings page: Failed to parse state from warmup data when expected',
      );
    }
  }

  const fetchers: StateFetchers = {
    fetchCurrentMember: async (currentMemberId: Nullable<string>) => {
      const members = await membersService.fetchCurrentAndViewedMember(
        currentMemberId,
        null,
      );
      state.currentMember = members.currentMember;
    },
    fetchRouteConfigurations: async () => {
      const routes = await membersAreaPublicAPI.getSettingsRoutes();
      state.routes = routes;
    },
    fetchSettingsPagePrefix: async () => {
      const pagePrefix = await pageService.getSettingsPagePrefix();
      state.settingsPagePrefix = pagePrefix.replace('/', '');
    },
  };

  const { state } = initState<SettingsPageState>({
    ...serializableState,
    ...fetchers,
  });

  return state;
};

export const fetchInitialSettingsPageData = async ({
  state,
}: Pick<SettingsPageContextProps, 'state'>) => {
  const { fetchRouteConfigurations, fetchSettingsPagePrefix } = state;
  return Promise.all([fetchRouteConfigurations(), fetchSettingsPagePrefix()]);
};

export const fetchCurrentMember = async (
  { state, flowAPI }: Pick<SettingsPageContextProps, 'state' | 'flowAPI'>,
  {
    currentUserService,
  }: Pick<SettingsPageContextServices, 'currentUserService'>,
) => {
  // Avoiding refetch in the editor - ids always differ in workspaces when using identity
  if (flowAPI.environment.isEditor && state.currentMember) {
    return { hasMemberChanged: false };
  }

  const currentMemberId = currentUserService.getCurrentUserId();
  const hasMemberChanged = currentMemberId !== state.currentMember?.id;

  if (hasMemberChanged) {
    await state.fetchCurrentMember(currentMemberId);
  }

  return { hasMemberChanged };
};
