import type {
  CurrentUserService,
  MenuItem,
  Nullable,
  RouteConfiguration,
  RouteDataContext,
  RouteDataService,
  WixCodeApi,
} from '../../../../types';
import { SLUG_PLACEHOLDERS } from '../../../../constants';

type ContextOptions = {
  locationAPI: Pick<WixCodeApi['location'], 'path'>;
  currentUserService: CurrentUserService;
  menuItems: MenuItem[];
  isEditor: boolean;
};

export class RouteData implements RouteDataService {
  private routes: RouteConfiguration[] = [];
  private membersAreaPagePrefix: string = '';

  constructor(private readonly contextOptions: ContextOptions) {}

  initializeRouteData({ routes, membersAreaPagePrefix }: RouteDataContext) {
    this.routes = routes;
    this.membersAreaPagePrefix = membersAreaPagePrefix;
  }

  getRouteData() {
    const membersAreaPagePath = this.getMembersAreaPagePath();
    const route = this.getRouteByPath(membersAreaPagePath?.routePath ?? null);

    return {
      slugOrId: membersAreaPagePath?.slugOrId ?? null,
      stateId: route?.state ?? null,
      isStatePrivate: route?.private ?? false,
      visibleForRoles: route?.vfr ?? [],
      path: route?.path ?? '',
      visibleWidgetId: route?.widgetId!,
    };
  }

  private getRouteByPath(routePath: Nullable<string>) {
    return routePath ? this.findRouteByPath(routePath) : this.getDefaultRoute();
  }

  private findRouteByPath(statePath: Nullable<string>) {
    return this.routes.find(({ path }) => path === statePath) ?? null;
  }

  private getDefaultRoute(): RouteConfiguration | null {
    if (this.contextOptions.isEditor) {
      return this.getFirstRoute();
    }

    const homeRoute = this.routes.find(({ home }) => home);
    return homeRoute ?? this.getFirstRoute();
  }

  private getFirstRoute(): RouteConfiguration | null {
    const [firstMenuItem] = this.contextOptions.menuItems;
    const menuItemPath = this.getRelativePathItemsFromMenuItem(firstMenuItem);

    return (
      this.routes.find(({ path }) => menuItemPath?.includes(path)) ??
      this.routes[0] ??
      null
    );
  }

  private getRelativePathItemsFromMenuItem(item: MenuItem) {
    if (item.link) {
      const indexOfMembersAreaPagePrefix = item.link.indexOf(
        this.membersAreaPagePrefix,
      );
      return item.link.slice(indexOfMembersAreaPagePrefix).split('/');
    }

    return null;
  }

  private getMembersAreaPagePath(): Nullable<{
    routePath: string;
    slugOrId: Nullable<string>;
  }> {
    const { path } = this.contextOptions.locationAPI;

    const indexOfMembersAreaPagePrefix = path.indexOf(
      this.membersAreaPagePrefix,
    );
    const membersAreaPagePath = path.slice(indexOfMembersAreaPagePrefix);
    const membersPathWithoutPrefix = membersAreaPagePath.slice(1);

    if (!membersAreaPagePath.length) {
      return null;
    }

    return {
      routePath: this.getRoutePath(membersPathWithoutPrefix),
      slugOrId: this.getSlugOrId(membersPathWithoutPrefix),
    };
  }

  private getSlugOrId(membersPathWithoutPrefix: string[]) {
    const pathContainsSlug = this.pathContainsSlug(membersPathWithoutPrefix);
    const slugOrId = membersPathWithoutPrefix[0];
    const { currentUserService } = this.contextOptions;

    if (!pathContainsSlug || SLUG_PLACEHOLDERS.includes(slugOrId)) {
      return currentUserService.getCurrentUserId();
    }

    return slugOrId;
  }

  private getRoutePath(membersPathWithoutPrefix: string[]) {
    const pathContainsSlug = this.pathContainsSlug(membersPathWithoutPrefix);

    if (!pathContainsSlug) {
      return membersPathWithoutPrefix[0];
    }

    return membersPathWithoutPrefix[1];
  }

  private pathContainsSlug(membersPathWithoutPrefix: string[]) {
    const routePath = membersPathWithoutPrefix[1];

    return this.routes.some(({ path }) => path === routePath);
  }
}
