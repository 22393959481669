import model from './model';
import { createContext } from './context';
import { ACTIVE_TAB_ID } from '../../constants/followers';

export default model.createController(({ $bind, $w, flowAPI, initState }) => {
  return {
    pageReady: async () => {
      const { environment, translations } = flowAPI;
      const { isBlocksPreview, isEditor } = environment;
      const { t } = translations;
      const { state, lightboxContext } = createContext({
        flowAPI,
        initState,
      });

      const defaultTab = lightboxContext.activeTab ?? ACTIVE_TAB_ID.Followers;
      const currentUser = flowAPI.controllerConfig.wixCodeApi.user.currentUser;
      const currentMemberId = currentUser.loggedIn ? currentUser.id : null;

      // @ts-expect-error
      $w('#tabs').changeTab(defaultTab);

      $bind('#title', {
        text: () => lightboxContext.title,
      });

      $bind('#closeButton', {
        // @ts-expect-error
        accessibility: {
          ariaAttributes: {
            label: () => t('app.ffmodal.header.close'),
          },
        },
        onClick: () => {
          const wixCodeAPI = flowAPI.controllerConfig.wixCodeApi;
          const lightboxAPI = wixCodeAPI.window.lightbox as {
            close?: Function;
          };

          return lightboxAPI.close?.();
        },
      });

      $bind('#followers', {
        // @ts-expect-error
        label: () => t('app.ffmodal.tab.followers'),
      });

      $bind('#following', {
        // @ts-expect-error
        label: () => t('app.ffmodal.tab.following'),
      });

      $bind('#followingMsb', {
        currentState: () => {
          return state.following.members.length > 0
            ? 'followingContent'
            : 'followingEmptyState';
        },
      });

      $bind('#followersMsb', {
        currentState: () => {
          return state.followers.members.length > 0
            ? 'followersContent'
            : 'followersEmptyState';
        },
      });

      $bind('#followersEmptyStateMsb', {
        currentState: () => {
          return currentMemberId === lightboxContext.memberId
            ? 'followersEmptyStateYourself'
            : 'followersEmptyStateOthers';
        },
      });

      $bind('#followingEmptyStateMsb', {
        currentState: () => {
          return currentMemberId === lightboxContext.memberId
            ? 'followingEmptyStateYourself'
            : 'followingEmptyStateOthers';
        },
      });

      $bind('#followersEmptyStateYourselfText', {
        text: () => t('app.ffmodal.empty-state.followers-yourself'),
      });

      $bind('#followersEmptyStateOthersText', {
        text: () => {
          return t('app.ffmodal.empty-state.followers-others', {
            memberName: lightboxContext.title,
          });
        },
      });

      $bind('#followingEmptyStateYourselfText', {
        text: () => t('app.ffmodal.empty-state.following-yourself'),
      });

      $bind('#followingEmptyStateOthersText', {
        text: () => {
          return t('app.ffmodal.empty-state.following-others', {
            memberName: lightboxContext.title,
          });
        },
      });

      $bind('#ffList1', {
        // @ts-expect-error
        members: () => state.following.members,
        // @ts-expect-error
        onMemberFollowed: ({ data: memberId }) => {
          return state.followMember(memberId);
        },
        // @ts-expect-error
        onMemberUnfollowed: ({ data: memberId }) => {
          return state.unfollowMember(memberId);
        },
        onLastMemberShown: () => {
          return state.fetchFollowing(lightboxContext.memberId);
        },
      });

      $bind('#ffList2', {
        // @ts-expect-error
        members: () => state.followers.members,
        // @ts-expect-error
        onMemberFollowed: ({ data: memberId }) => {
          return state.followMember(memberId);
        },
        // @ts-expect-error
        onMemberUnfollowed: ({ data: memberId }) => {
          return state.unfollowMember(memberId);
        },
        onLastMemberShown: () => {
          return state.fetchFollowers(lightboxContext.memberId);
        },
      });

      // TODO: In demo mode we will want to show fake data.
      // It is needed for editing experience.
      if (isBlocksPreview || isEditor) {
        return;
      }

      await state.fetchFollowingFollowers(lightboxContext.memberId);
    },
    exports: {},
  };
});
