import { ContextProps, ContextServices } from '../../../types';
import { fetchViewedAndCurrentMember } from '../viewer-controller';
import { handleNavigation, redirectTo404 } from './navigation';
import {
  addInitialDataForWidgetPlugins,
  emitMemberChangedEventToPlugins,
} from './widget-plugins';

export const addLocationChangeHandler = (
  contextProps: ContextProps,
  contextServices: ContextServices,
) => {
  const { wixCodeApi, state } = contextProps;
  const {
    routeDataService,
    menuService,
    multiStateBoxService,
    errorStateService,
    widgetPluginHostService,
  } = contextServices;

  return wixCodeApi.location.onChange(async () => {
    const { stateId } = routeDataService.getRouteData();

    const { hasMemberChanged } = await fetchViewedAndCurrentMember(
      contextProps,
      contextServices,
    );

    if (!stateId) {
      return redirectTo404(multiStateBoxService, errorStateService);
    }

    if (hasMemberChanged) {
      addInitialDataForWidgetPlugins(contextProps, contextServices);
      await emitMemberChangedEventToPlugins(contextProps, contextServices);
      menuService.restoreMenuItems(state);
    }

    await widgetPluginHostService.membersAreaWidgetReady();
    await multiStateBoxService.showMainAppState();

    return handleNavigation(contextProps, contextServices);
  });
};
