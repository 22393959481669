import { getPageIdAndAppDefIdFromWidgetId } from '@wix/members-area-app-definitions';
import { membersAreaNavigation } from '@wix/bi-logger-members-app-uou/v2';

import {
  ContextProps,
  ContextServices,
  FlowAPI,
  MenuItem,
  RouteConfiguration,
} from '../../../types';
import {
  Experiment,
  ViewerMenuId,
  WARMUP_DATA_STATE_KEY,
} from '../../../constants';
import {
  fetchInitialMemberPageData,
  fetchViewedAndCurrentMember,
  getSerialisableState,
} from './state';
import { addHandlers } from '../handlers';
import { log } from '../../../editor/services/monitor';

const MENU_ARIA_LABEL = 'Member Page';

const emitMembersAreaNavigationEvent = (
  flowAPI: FlowAPI,
  routes: RouteConfiguration[],
  menuItem: MenuItem,
) => {
  const routeConfig = routes.find((route) =>
    menuItem?.link?.includes(route.path),
  );

  if (!routeConfig) {
    return;
  }

  const { pageId, appDefId } = getPageIdAndAppDefIdFromWidgetId(
    routeConfig.widgetId,
  );

  flowAPI.bi?.report(
    membersAreaNavigation({
      pageName: pageId,
      action: 'menu-item-click',
      component: 'mav2-navigation',
      originAppId: appDefId,
    }),
  );
};

const bindElements = (
  { bind, flowAPI, state }: ContextProps,
  { menuService }: ContextServices,
) => {
  const accessibility = {
    ariaAttributes: {
      label: () => MENU_ARIA_LABEL,
    },
  };

  bind('#homeButton', {
    link: () => '/',
  });

  bind(ViewerMenuId.Horizontal, {
    menuItems: () => menuService.currentMenuItems,
    onItemClick: (event) => {
      // @ts-expect-error // Bind types are failing in CI
      emitMembersAreaNavigationEvent(flowAPI, state.routes, event.item);
    },
    // @ts-expect-error // Bind types are failing in CI
    accessibility,
  });

  bind(ViewerMenuId.Vertical, {
    // @ts-expect-error // Bind types are failing in CI
    menuItems: () => menuService.currentMenuItems,
    onItemClick: ({ item }: { item: MenuItem }) => {
      emitMembersAreaNavigationEvent(flowAPI, state.routes, item);
    },
    accessibility,
  });
};

const saveWarmupData = (
  contextProps: ContextProps,
  contextServices: ContextServices,
) => {
  if (
    !contextProps.flowAPI.environment.isSSR ||
    !contextProps.flowAPI.experiments.enabled(Experiment.FasterMemberFetching)
  ) {
    return;
  }

  try {
    contextServices.warmupDataService.set(
      WARMUP_DATA_STATE_KEY,
      getSerialisableState(contextProps.state),
    );
  } catch (e) {
    log('Failed to save warmup data', { extra: { e } });
  }
};

const setInitialViewProps = (
  multiStateBoxService: ContextServices['multiStateBoxService'],
) => multiStateBoxService.showMainAppState();

// To do: multiple requests can be async for performance
export const pageReady = async (
  contextProps: ContextProps,
  contextServices: ContextServices,
) => {
  const { widgetPluginHostService, menuService, routeDataService } =
    contextServices;

  widgetPluginHostService.setInitialData();
  await setInitialViewProps(contextServices.multiStateBoxService);

  await fetchInitialMemberPageData(contextProps, contextServices);
  routeDataService.initializeRouteData(contextProps.state);

  await fetchViewedAndCurrentMember(contextProps, contextServices);
  menuService.initializeMenuItems(contextProps.state);
  bindElements(contextProps, contextServices);

  saveWarmupData(contextProps, contextServices);

  return addHandlers(contextProps, contextServices);
};

export { fetchViewedAndCurrentMember };
