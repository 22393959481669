import type { Nullable, ViewerPublicAPI, PublicApiContext } from '../types';
import { PublicAPI } from './public-api';
import { ViewerPublicApiError } from '../constants';

let _context: Nullable<PublicApiContext> = null;

export const setContextForPublicAPI = (context: PublicApiContext) => {
  _context = context;
};

const getPublicAPI = () => {
  if (!_context) {
    throw new Error(ViewerPublicApiError.MissingContext);
  }

  return new PublicAPI(_context);
};

export const createPublicAPI = (): ViewerPublicAPI => {
  return {
    getViewedUser: (onSuccess, onError) =>
      getPublicAPI().getViewedUser(onSuccess, onError),
    enterPublicProfilePreviewMode: () =>
      getPublicAPI().enterPublicProfilePreviewMode(),
    leavePublicProfilePreviewMode: () =>
      getPublicAPI().leavePublicProfilePreviewMode(),
    clearMenus: () => getPublicAPI().clearMenus(),
    openBlockedMemberEmptyState: () =>
      getPublicAPI().openBlockedMemberEmptyState(),
  };
};
