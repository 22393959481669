import { makeAutoObservable } from 'mobx';

import type { SettingsPageMenuService, MenuItem } from '../../../types';

export class MenuService implements SettingsPageMenuService {
  private pagePrefix!: string;

  constructor(public currentMenuItems: MenuItem[]) {
    makeAutoObservable(this);
  }

  initializeMenuItems(menuContext: { settingsPagePrefix: string }) {
    this.pagePrefix = menuContext.settingsPagePrefix;
  }

  selectMenuItem(selectedMenuItemLinkIdentifier: string) {
    this.currentMenuItems = this.currentMenuItems.map((menuItem) => {
      const path = this.getRelativePathItemsFromMenuItem(menuItem);

      if (path) {
        const routePathIndex = 1;
        const routePath = path[routePathIndex];

        return {
          ...menuItem,
          selected: routePath === selectedMenuItemLinkIdentifier,
        };
      }

      return menuItem;
    });
  }

  private getRelativePathItemsFromMenuItem(item: MenuItem) {
    if (item.link) {
      const indexOfMembersAreaPagePrefix = item.link.indexOf(this.pagePrefix);
      return item.link.slice(indexOfMembersAreaPagePrefix).split('/');
    }

    return null;
  }
}
