export const getIsTabAccessibleWithUserRoles = (
  viewedMemberRoles: string[],
  visibleForRoles: string[],
) => {
  if (!visibleForRoles.length) {
    return true;
  }

  return viewedMemberRoles.some((roleKey) => visibleForRoles.includes(roleKey));
};
