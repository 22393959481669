import { FlowAPI, InstanceService } from '../../types';
import { CurrentUserFromInstanceService } from './current-user-from-instance';
import { CurrentUserFromWixCodeService } from './current-user-from-wix-code';

export const currentUserServiceFactory = (
  flowAPI: FlowAPI,
  instanceService: InstanceService,
) => {
  const { environment, controllerConfig } = flowAPI;
  return environment.isEditor
    ? new CurrentUserFromInstanceService(instanceService)
    : new CurrentUserFromWixCodeService(controllerConfig.wixCodeApi.user);
};
