import { W } from '../../../types';

const FADE_ANIMATION = 'fade';
const FADE_LENGTH = 200;

export class AnimationsService {
  constructor(private $w: W) {}

  async hideApplication() {
    return this.$w('#appContainer').hide();
  }

  async showApplication() {
    return this.$w('#appContainer').show(FADE_ANIMATION, {
      duration: FADE_LENGTH,
    });
  }

  async hideVerticalsSection() {
    return this.$w('#appsContainer').hide(FADE_ANIMATION, {
      duration: FADE_LENGTH,
    });
  }

  async showVerticalsSection() {
    return this.$w('#appsContainer').show(FADE_ANIMATION, {
      duration: FADE_LENGTH,
    });
  }
}
