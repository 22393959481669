export const CTA_ACTION = {
  Follow: 'follow',
  Unfollow: 'unfollow',
  None: 'none',
} as const;

export const ACTIVE_TAB_ID = {
  Followers: 'followers',
  Following: 'following',
} as const;
