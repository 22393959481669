import { getPageIdAndAppDefIdFromWidgetId } from '@wix/members-area-app-definitions';
import { membersAreaNavigation } from '@wix/bi-logger-members-app-uou/v2';

import type {
  MenuItem,
  SettingsPageContextProps,
  SettingsPageContextServices,
} from '../../../types';

import { WARMUP_DATA_STATE_KEY } from '../../../constants';
import {
  fetchCurrentMember,
  fetchInitialSettingsPageData,
  getSerializableState,
} from './state';
import { addHandlers } from '../handlers';

const MENU_ARIA_LABEL = 'Member Settings Page';

const accessibility = {
  ariaAttributes: {
    label: () => MENU_ARIA_LABEL,
  },
};

const emitMembersAreaNavigationEvent = (
  flowAPI: SettingsPageContextProps['flowAPI'],
  routes: SettingsPageContextProps['state']['routes'],
  menuItem: MenuItem,
) => {
  const routeConfig = routes.find((route) =>
    menuItem?.link?.includes(route.path),
  );

  if (!routeConfig) {
    return;
  }

  const { pageId, appDefId } = getPageIdAndAppDefIdFromWidgetId(
    routeConfig.widgetId,
  );

  flowAPI.bi?.report(
    membersAreaNavigation({
      pageName: pageId,
      action: 'menu-item-click',
      component: 'mav2-navigation',
      originAppId: appDefId,
    }),
  );
};

const bindElements = (
  {
    flowAPI,
    state,
    bind,
  }: Pick<SettingsPageContextProps, 'flowAPI' | 'state' | 'bind'>,
  { menuService }: Pick<SettingsPageContextServices, 'menuService'>,
) => {
  bind('#horizontalMenu', {
    menuItems: () => menuService.currentMenuItems,
    onItemClick: (event) => {
      // @ts-expect-error // Bind types are failing in CI
      emitMembersAreaNavigationEvent(flowAPI, state.routes, event.item);
    },
    // @ts-expect-error // Bind types are failing in CI
    accessibility,
  });

  bind('#expandableMenu1', {
    // @ts-expect-error // Bind types are failing in CI
    menuItems: () => menuService.currentMenuItems,
    onItemClick: ({ item }: { item: MenuItem }) => {
      emitMembersAreaNavigationEvent(flowAPI, state.routes, item);
    },
    accessibility,
  });

  bind('#homeButton', {
    link: () => '/',
  });
};

const saveWarmupData = (
  { flowAPI, state }: Pick<SettingsPageContextProps, 'flowAPI' | 'state'>,
  {
    warmupDataService,
    monitoringService,
  }: Pick<
    SettingsPageContextServices,
    'warmupDataService' | 'monitoringService'
  >,
) => {
  if (flowAPI.environment.isSSR) {
    try {
      warmupDataService.set(WARMUP_DATA_STATE_KEY, getSerializableState(state));
    } catch {
      monitoringService.log('Settings Page: failed to set warmup data');
    }
  }
};

const setInitialViewProps = (
  multiStateBoxService: SettingsPageContextServices['multiStateBoxService'],
) => multiStateBoxService.showMainAppState();

export const pageReady = async (
  contextProps: SettingsPageContextProps,
  contextServices: SettingsPageContextServices,
) => {
  contextServices.widgetPluginHostService.setInitialData();
  await setInitialViewProps(contextServices.multiStateBoxService);

  await fetchInitialSettingsPageData(contextProps);
  contextServices.routeDataService.initializeRouteData({
    routes: contextProps.state.routes,
    membersAreaPagePrefix: contextProps.state.settingsPagePrefix,
  });

  await fetchCurrentMember(contextProps, contextServices);
  contextServices.menuService.initializeMenuItems(contextProps.state);
  bindElements(contextProps, contextServices);
  saveWarmupData(contextProps, contextServices);

  return addHandlers(contextProps, contextServices);
};
