import type { IHttpClient } from '@wix/yoshi-flow-editor';

import {
  followMember,
  unfollowMember,
} from '@wix/ambassador-members-v3-follow/http';

import type { FollowersWriteService as IFollowersWriteService } from '../../../types/followers';

export class FollowersWriteService implements IFollowersWriteService {
  constructor(private readonly httpClient: IHttpClient) {}

  async follow(memberId: string): Promise<void> {
    await this.httpClient.request(followMember({ memberId }));
  }

  async unfollow(memberId: string): Promise<void> {
    await this.httpClient.request(unfollowMember({ memberId }));
  }
}
