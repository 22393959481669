import type {
  SettingsPageContextProps,
  SettingsPageContextServices,
} from '../../../types';

import { addLocationChangeHandler } from './location';
import { addLoginHandler } from './login';
import { addInitialDataForWidgetPlugins } from './widget-plugins';
import { handleNavigation } from './navigation';

export const addHandlers = (
  contextProps: SettingsPageContextProps,
  contextServices: SettingsPageContextServices,
) => {
  addInitialDataForWidgetPlugins(contextProps, contextServices);
  addLocationChangeHandler(contextProps, contextServices);
  addLoginHandler(contextProps, contextServices);
  return handleNavigation(contextProps, contextServices);
};
