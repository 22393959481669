export class NavigationStateService {
  private loadedStates: string[] = [];

  addLoadedState(state: string) {
    this.loadedStates.push(state);
  }

  isStateLoaded(state: string) {
    return !!this.loadedStates.find((loadedState) => state === loadedState);
  }
}
