import type { WarmupDataService as IWarmupDataService } from '../types';

export class WarmupDataService implements IWarmupDataService {
  private callMap: Record<string, boolean> = {};

  constructor(private warmupDataService: IWarmupDataService) {}

  get<T>(key: string) {
    if (this.callMap[key]) {
      return null;
    }

    const warmupData = this.warmupDataService.get<T>(key);
    this.callMap[key] = true;
    return warmupData;
  }

  set<T>(key: string, value: T) {
    return this.warmupDataService.set(key, value);
  }
}
