import type { PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';

import type { MonitoringService as IMonitoringService } from '../types/services';

export const getLogEnvironmentTags = ({
  environment,
  controllerConfig,
}: {
  environment: PlatformControllerFlowAPI['environment'];
  controllerConfig: PlatformControllerFlowAPI['controllerConfig'];
}) => ({
  widgetId: environment.widgetId,
  isEditorX: environment.isEditorX,
  isADI: environment.isADI,
  isClassicEditor: environment.isClassicEditor,
  isSSR: environment.isSSR,
  language: environment.language,
  isRTL: environment.isRTL,
  isSEO: environment.isSEO,
  isMobile: environment.isMobile,
  isEditor: environment.isEditor,
  isPreview: environment.isPreview,
  isBlocksPreview: environment.isBlocksPreview,
  isViewer: environment.isViewer,

  metaSiteId: controllerConfig?.platformAPIs.bi?.metaSiteId,
  ownerId: controllerConfig?.platformAPIs.bi?.ownerId,
  biToken: controllerConfig?.platformAPIs.bi?.biToken,
  artifactVersion: controllerConfig?.platformAPIs.bi?.artifactVersion,
  dc: controllerConfig?.platformAPIs.bi?.dc,
  isCached: controllerConfig?.platformAPIs.bi?.isCached,
  isPopup: controllerConfig?.platformAPIs.bi?.isPopup,
  biIsPreview: controllerConfig?.platformAPIs.bi?.isPreview,
  isServerSide: controllerConfig?.platformAPIs.bi?.isServerSide,
  isjp: controllerConfig?.platformAPIs.bi?.isjp,
  pageUrl: controllerConfig?.platformAPIs.bi?.pageUrl,
  requestId: controllerConfig?.platformAPIs.bi?.requestId,
  svSession: controllerConfig?.platformAPIs.bi?.svSession,
  viewMode: controllerConfig?.platformAPIs.bi?.viewMode,
  viewerName: controllerConfig?.platformAPIs.bi?.viewerName,
  viewerSessionId: controllerConfig?.platformAPIs.bi?.viewerSessionId,
});

export class MonitoringService implements IMonitoringService {
  constructor(
    private fedops: PlatformControllerFlowAPI['fedops'],
    private errorMonitor: PlatformControllerFlowAPI['errorMonitor'],
    private environment: PlatformControllerFlowAPI['environment'],
    private controllerConfig: PlatformControllerFlowAPI['controllerConfig'],
  ) {}

  async toMonitored<T>(
    interactionName: string,
    promise: () => Promise<T> | T,
  ): Promise<T> {
    try {
      this.interactionStarted(interactionName);
      const response = await promise();
      this.interactionEnded(interactionName);
      return response;
    } catch (err) {
      this.interactionFailed(interactionName, err as Error);
      throw err;
    }
  }

  log(message: string) {
    return this.errorMonitor?.captureMessage(message, { level: 'info' as any });
  }

  private interactionStarted(interactionName: string) {
    try {
      this.fedops.interactionStarted(interactionName);
    } catch (e) {
      const err = 'Failed to start fedops interaction, reason: ' + e;
      this.errorMonitor?.captureException(new Error(err));
    }
  }

  private interactionEnded(interactionName: string) {
    try {
      this.fedops.interactionEnded(interactionName);
    } catch (e) {
      const err = 'Failed to end fedops interaction, reason: ' + e;
      this.errorMonitor?.captureException(new Error(err));
    }
  }

  private interactionFailed(interactionName: string, err: Error) {
    this.errorMonitor?.captureException(err, {
      tags: {
        ...getLogEnvironmentTags({
          environment: this.environment,
          controllerConfig: this.controllerConfig,
        }),
        interactionName,
      },
    });
  }
}
