import type {
  SettingsPageErrorStateService,
  SettingsPageW,
  TFunction,
} from '../../../types';

export class ErrorStateService implements SettingsPageErrorStateService {
  constructor(
    private readonly $w: SettingsPageW,
    private readonly t: TFunction,
  ) {}

  setToNotFoundContent() {
    this.$w('#text1').text = this.t('app.empty-state.not-found.title');
    this.$w('#text2').text = this.t('app.empty-state.not-found.description');
    this.$w('#homeButton').label = this.t('app.empty-state.not-found.button');
    this.$w('#homeButton').link = '/';
  }
}
