import { PageService as IPageService, WixCodeApi } from '../types';
import {
  PROFILE_PAGE_BOB_APP_DEF_ID,
  MEMBER_TPA_PAGE_ID,
  MEMBER_SETTINGS_TPA_PAGE_ID,
} from '../constants';

export class PageService implements IPageService {
  constructor(
    private readonly siteAPI: Pick<WixCodeApi['site'], 'getSectionUrl'>,
  ) {}

  async getProfilePagePrefix() {
    const profilePagePrefix = await this.siteAPI.getSectionUrl({
      appDefinitionId: PROFILE_PAGE_BOB_APP_DEF_ID,
      sectionId: MEMBER_TPA_PAGE_ID,
    });

    if (!profilePagePrefix?.relativeUrl) {
      throw new Error('Error: missing Profile page - failed to find prefix');
    }

    return profilePagePrefix.relativeUrl;
  }

  async getSettingsPagePrefix() {
    const settingsPagePrefix = await this.siteAPI.getSectionUrl({
      appDefinitionId: PROFILE_PAGE_BOB_APP_DEF_ID,
      sectionId: MEMBER_SETTINGS_TPA_PAGE_ID,
    });

    if (!settingsPagePrefix?.relativeUrl) {
      throw new Error('Error: missing Settings page - failed to find prefix');
    }

    return settingsPagePrefix.relativeUrl;
  }
}
